
import { EventBus } from "@/plugins/eventBus"
import axios, { AxiosError, AxiosResponse } from "axios"
import Vue from "vue"
import { mutations, state } from "@/plugins/state"

export interface Agency {
  name: string,
  id: number,
  url: string,
  sms: boolean,
  ocr: boolean
  association_code: string
}

export default Vue.extend({
  name: "SelectSpace",
  props: {
    updateLocaleStorage: {
      type: Boolean,
      default: false
    },
    enableSelectAll: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: () => [] as string[]
    }
  },
  model: {
    event: 'emitAgencyId'
  },
  data: () => ({
    agencies: [] as Array<Agency>,
    value: null as null | number,
    dataRules: [] as Array<boolean | string>,
    space: null as Agency | null,
    // need to show error message into initialization select (default value)
    errorMessages: [] as string[]
    }),
  mounted() {
    this.getAgencies()
    this.value = this.selectSpace
    },
  computed: {
    requiredRule() {
      return (v: string) => !!v || this.$vuetify.lang.t('$vuetify.required_field')
    },
    urlRule() {
      return (v:string) => !!v && v.length > 0 || this.$vuetify.lang.t('$vuetify.url_express_deactivate')
    },
    smsRule() {
      return (v: boolean) => v || this.$vuetify.lang.t('$vuetify.sms_deactivate')
    },
    ocrRule() {
      return (v: boolean) => v || this.$vuetify.lang.t('$vuetify.url_express_deactivate')
    },
    associationCodeRule() {
      return (v: string) => !!v || this.$vuetify.lang.t('$vuetify.mobile_app_deactivate')
    },
    selectSpace: {
      get () {
        this.emitAgencyId()
        return Number(state.agency)
      },
      set (value: number) {
        // if id = 0 (select all spaces)
        this.value = value
        this.getFullAgency()
        if (!value && this.enableSelectAll) {
          mutations.resetSpaces()
          return value
        }
        this.rulesConstructor(this.rules as Array<string>)
        if (this.updateLocaleStorage) {
          mutations.setAgency(value.toString())
          if (this.space) {
            mutations.setSpaceName(this.space.name)
          }
        }
      }
    },
  },
  watch: {
    selectSpace : function() {
      this.rulesConstructor(this.rules as Array<string>)
    }
  },
  methods: {
    getFullAgency() {
      this.space = this.agencies.filter((agency: Agency) => agency.id === this.value)[0]
      this.$emit('getFullSpace', this.space)
    },
    getAgencies(){
      axios
      .get("/v1/agencies?deleted_at=false")
      .then((response: AxiosResponse) => {
        this.agencies = response.data
        if (this.enableSelectAll) {
          // The only information used here are the name and the id
          // The other properties hold default value to please the Agency interface
          this.agencies.unshift({
            "id": 0,
            "name": this.$vuetify.lang.t("$vuetify.all_spaces"),
            "url": "",
            "association_code": "",
            "ocr": false,
            "sms": false
          })
        }
        this.getFullAgency()
        this.rulesConstructor(this.rules as Array<string>)
      })
      .catch((error: AxiosError) => {
        EventBus.$emit('snackbar',{axiosError: error})
      })
    },
    emitAgencyId() {
      this.$emit('emitAgencyId', this.value)
    },
    emitError(errorDetected: boolean) {
      this.$emit('emitError', errorDetected)
    },
    rulesConstructor(propsRules: Array<string>) {
      this.errorMessages = []
      this.dataRules = []
      let detectedErrors: string[] = []
      // order of switch represent priority of showing message rules error
      if (this.space) {
        propsRules.forEach((propsRule: string) => {
          let processValidationResult: boolean | string = true
          switch (propsRule) {
            case 'url':
              processValidationResult = this.urlRule(this.space!.url)
              if(typeof processValidationResult === 'string') {
                detectedErrors.push(processValidationResult)
              }
            break
            case 'sms':
              processValidationResult = this.smsRule(this.space!.sms)
              if(typeof processValidationResult === 'string') {
                detectedErrors.push(processValidationResult)
              }
            break
            case 'required':
              processValidationResult = this.requiredRule(JSON.stringify(this.selectSpace))
              if(typeof processValidationResult === 'string') {
                detectedErrors.push(processValidationResult)
              }
            break
            case 'ocr':
              processValidationResult = this.ocrRule(this.space!.ocr)
              if(typeof processValidationResult === 'string') {
                detectedErrors.push(processValidationResult)
              }
            break
            case 'association_code':
              processValidationResult = this.associationCodeRule(this.space!.association_code)
              if(typeof processValidationResult === 'string') {
                detectedErrors.push(processValidationResult)
              }
            break
            default:
            break
          }
          this.dataRules.push(processValidationResult)
        })
        this.errorMessages = detectedErrors
        this.emitError(detectedErrors.length > 0)
      } else {
        this.emitError(true)
      }
    }
  }
})
