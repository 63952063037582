import es from "vuetify/src/locale/es"

export default {
  ...es,
  locale: "Español",
  code: "es",
  or: "o",
  // Developement drawer menu & associated <h1> title
  welcome: "Bienvenue",
  home: "Accueil",
  about: "À propos",
  worker_list: "Liste du personnel",
  orders: "Commandes",
  localization_examples: "Examples de localisation",
  clients_list: "Liste de clients",
  expand_menu: "Étendre le menu",
  collapse_menu: "Réduire le menu",
  workers_list: "Liste du personnel",
  // Production drawer menu & associated <h1> title
  delegates: "DPAE & Confirmation",
  back_to_v1: "Retour à la version actuelle",
  logout: "Se déconnecter",
  user_settings: "Paramètres utilisateur",
  change_space: "Changer d'espace",
  // Dispojob API request known errors
  api_errors: {
    401: "Informations d'authentification invalides, veuillez vous reconnecter", // Unauthorized
    403: "Vous n'avez pas la permission d'utiliser cette ressource", // Forbidden
    404: "La ressource demandée n'existe pas", // Unauthorized
    415: "Format de fichier non supporté", // Unsupported Media Type
    502: "Problème de connexion à l'API, veuillez réessayer ou contacter le support", // Bad Gateway, happened while restarting docker container
    500: "Erreur API, veuillez contacter le support", // Internal server error
    network: "Problème de connexion à l'API, veuillez réessayer ou contacter le support",
    "TypeError: Failed to fetch": "Problème de connexion à l'API, veuillez réessayer ou contacter le support",
    unknown: "Erreur inconnue, veuillez réessayer ou contacter le support",
    "User has no rights": "Vous n'avez pas accès à la ressource",
    "Invalid status": "Le statut est invalide"
  },
  // /workers page
  id: "Id",
  space_id: "Id espace",
  workers: 'Personnels',
  birth_city: "Lugar de nacimiento",
  birth_country: "País de nacimiento",
  nir: "Número de la seguridad social",
  // /clients page
  name: "Nom",
  // Pagination component
  pagination_title: "Données paginées",
  // /delegates page
  delegates_description: "Une DPAE & Confirmation est une délégation de personnel chez un de vos clients.\nElle vous permet d'automatiser la D.P.A.E à l'URSSAF et l'envoi du bon de mission par SMS pour chaque personnel que vous déléguez",
  model: "Poste",
  customer: "Client",
  creation_date: "Date de création",
  starting_date: "Date de début",
  ending_date: "Date de fin",
  // Themes
  theme_light: "Thème clair",
  theme_dark: "Thème sombre",
  // 404 page
  not_found: "Cette page n’a pas été trouvée",
  not_found_text1: "Merci de nous faire part de cette erreur en cliquant sur le lien suivant: ",
  not_found_text2: "Accès au portail servicedesk",
  not_found_text3: "Vous avez reçu un lien pour créer votre compte sur le servicedesk. Nous traiterons cette demande dans les plus brefs délais. N'hésitez pas à faire une capture d'écran afin de nous aider à résoudre l'incident au plus vite.",
  // /login page
  login_page: {
    welcome_message: "Bienvenue sur votre espace privé Dispojob. L'accès à votre compte est confidentiel.", 
    forgot_password: "Mot de passe oublié ?",
    login: "Connexion",
    log_in: "Se connecter",
    password: "Mot de passe",
    valid_password: "Mot de passe valide",
    min_char: "6 caractères au minimum",
    brochure: "Catalogue de formations en ligne"
  },
  // /inscription page
  subscription_page: {
    error: {
      unknown: "Por favor, compruebe el formulario haciendo clic atrás o póngase en contacto con la agencia para finalizar su inscripción.",
      "Invalid email": "El correo electrónico no es válido",
      "Field space_id is required": "Elija un espacio",
      "Invalid sex": "El civismo no es válido",
      "Invalid first_name": "El nombre no es válido",
      "Invalid last_name": "El apellido no es válido",
      "Invalid mobile_phone_number": "El número de teléfono móvil no es válido",
      "Invalid postal_address": "La dirección postal no es válida",
      "Invalid postal_complement": "El complemento de dirección no es válido",
      "Invalid postal_code": "El código postal no es válido",
      "Invalid postal_city": "La ciudad no es válida",
      "Invalid postal_country": "El país no es válido",
      "Invalid birth_date (awaited format 'YYYY-MM-DD')": "La fecha de nacimiento no es válida",
      "Invalid birth_date (wrong value)": "La fecha de nacimiento no es válida",
      "Invalid birth_city": "El lugar de nacimiento no es válido",
      "Invalid birth_country": "El país de nacimiento no es válido",
      "Invalid nir (Pattern)": "El número de la seguridad social no es válido",
      "Invalid nir (Key)": "La clave de verificación de este número de la seguridad social no es válida",
      "email or mobile_phone_number already taken": "Correo electrónico o número de teléfono móvil ya utilizado",
      "Invalid favorite_jobs size": "Los trabajos deben tener entre 2 y 255 caracteres.",
      "Invalid favorite_jobs type": "Los empleos buscados no pueden ser números."
    },
    favorite_jobs: "Ofertas de empleo",
    non_compliant_file_error: "Error, archivo no conforme",
    error_no_space: "Error, no hemos encontrado ninguna espacio que coincida con el enlace introducido.",
    prepare_documents: "Prepare tus documentos",
    mandatory_documents: "Documento obligatorio",
    mandatory_resume: "Debe proporcionar al menos su CV",
    documents_complete_file: "Documento para un expediente completo",
    your_resume: "Tu hoja de vida",
    resume: "hoja de vida",
    identity_document: "IDENTIFICACIÓN",
    social_security_document: "Tarjeta vital o certificado",
    residence_proof:" Prueba de dirección",
    banque_identity: "RNB",
    work_certificate: "Certificado de trabajo",
    training_certificate: "Certificados de formación",
    medical_checkup: "Visita/aptitud médica",
    documents_quality_info: "Sus documentos y fotos deben ser de buena calidad y estar bien enmarcados.",
    documents_format_info: "Los archivos subidos deben tener un tamaño inferior a 8 MB y estar en los siguientes formatos: .jpg, .png, .pdf, .doc o .docx",
    complete_file_warning: "Cuanto más completo sea su expediente, antes tendrá la oportunidad de trabajar.",
    file_usage:"Al enviar este formulario, acepto que la información introducida pueda ser utilizada en relación con mi solicitud de empleo, la gestión de mi empleabilidad y la relación contractual y/o comercial que pueda resultar.",
    sign_in:"Inscríbete",
    marital_status_info: "La siguiente información se recoge para declarar su empleo en Francia. Si este no es su caso, haga clic en Continuar ",
    invalid_nir: "Este número de seguridad social no es válido",
    invalid_nir_key: "La clave de verificación de este número de seguridad social no es válida",
    other_id:"Otra identificación",
    other_documents: "Otro documento",
    confirmation: {
      title: "¡Felicidades, ya está hecho!",
      text_1: "Enhorabuena, la inscripción ha finalizado.",
      text_2: "Su empleador o reclutador se pondrá en contacto con usted para el resto del proceso de contratación.",
      text_3: "Buena suerte y buena contratación.",
      video_1: "Vídeo de funcionamiento",
      video_2: "1 texto, 1 trabajo",
      new_subscription: "Nuevo registro"
    },
    cet_consent: "Quiero abrir una cuenta de ahorro a plazo fijo.",
    kiosk_subscription: "Registro de terminales",
    portrait_guide: "Coloca tu cara en el centro de la lente",
    scanner_guide: "Coloque el documento en el soporte del escáner",
    webcam_error: "Se ha producido un error. Por favor, comprueba los permisos de la cámara web o si está siendo utilizada por otra fuente."
  },
  drag_and_drop: "arrastrar y soltar",
  identity_card: "Documento de identidad",
  identity_card_verso: "Carnet de identidad en el reverso",
  passport: "Pasaporte",
  caces: "CACES",
  habilitation: "Habilitación de",
  license: "Licencia",
  diploma: "Diploma",
  driver_licence: "Permiso de conducir",
  other: "Otros",
  subscription: "Registro",
  photo: "Foto",
  birth_date:'Fecha de nacimiento',
  required_field: "Campo obligatorio",
  preparation: "Preparación",
  contact_details: "Datos de contacto",
  marital_status: "Estado civil",
  documents: "Documentos",
  resident_permit: "Permiso de residencia",
  confirmation: "Confirmación",
  continue: "Continuar",
  return: "Volver",
  // Calendar
  fullcalendar: "Calendrier Complêt",
  // /calendar page
  status: "Statut",
  available: "Disponible",
  unavailable: "Indisponible",
  unknown :"desconocido",
  contract: "En contrat",
  time_start: "Heure de début",
  time_end: "Heure de fin",
  event_created: "Évènement créé",
  event_updated: "Évènement mis à jour",
  event_deleted: "Évènement supprimé",
  // Parameters
  language: 'Langue',
  // Generic terms
  title: "Titre",
  create: "Créer",
  delete: "Supprimer",
  update: "Mettre à jour",
  close: "Fermer",
  cancel: "Annuler",
  choose_space: "Choisir un espace",
  space: "Espacio",
  mandatory: "Obligatorio",
  optional: "Opcional",
  required: "Requerido",
  email: "Correo electrónico",
  phone: "Teléfono",
  valid_email: "Dirección de correo electrónico válida",
  invalid_email: "Dirección de correo electrónico no válida",
  first_name: "Nombre",
  firstname: "Nombre",
  last_name: "Apellido",
  lastname: "Apellido",
  postal_address: "Dirección postal",
  postal_complement: "Dirección adicional",
  postal_code: "Código postal",
  city: "Ciudad",
  country: "País",
  civility: "Civismo",
  mister: "Señor",
  misses: "Señora",
  // transport
  means_transport: "Medios de transporte",
  car: "auto",
  motorbike: "moto",
  bike_scooter: "bicicleta, patinete",
  mass_transit: "transporte público",
  moped: "scooter",
  walk: "a pie",
  carpool: "compartir coche",
  password: "Contraseña",
  none: "no",
  invalid_size_array: "No puede añadir más de 3 operaciones.",
  // mobile phone number input
  mobile_phone_number: "Número de teléfono móvil",
  invalid_mobile_phone_number: "Número de teléfono móvil no válido",
  invalid_length: "La longitud del texto no es válida",
  is_numeric: "Este campo sólo debe contener números enteros",
  is_string: "Este campo no debe contener números",
  // Planning
  week: "Semaine",
  month: "Mois",
  date_from: "Du",
  date_to: "Au",
  // Roles - Permissions
  permission: "Permission",
  permissions_names: {
    "app-admin": "Administrateur application",
    "space-users": "Administrateur espace - CRUD utilisateurs",
    "jobs-delegate": "DPAE & Confirmation de mission",
    "orders": "Commandes",
    "multiposting": "Multidiffusion"
  },
  permissions_descriptions: {
    "app-admin": "A tous les droits sur toute l'application",
    "space-users": "Vous êtes administrateur de votre Espace, vous pouvez créer des utilisateurs, réinitialiser leur mot de passe, etc...",
    "jobs-delegate": "A le droit d'utiliser la fonctionnalité DPAE & Confirmation de mission",
    "orders": "A le droit d'utiliser la fonctionnalité Commandes",
    "multiposting": "A le droit d'utiliser l'application Multidiffusion"
  },
  // Admin
  admin: {
    title: "Administration application",
    users: {
      title: "Utilisateurs",
    }
  },
  // Space administration page
  space_admin: {
    title: "Administration espace",
    users: {
      title: "Utilisateurs",
    }
  },
  // User list
  user_list: "Liste des utilisateurs",
  permissions: "Permissions",
  spaces: "Espacio",
  groups: "Groupes",
  last_login_at: "Dernière connexion",
  v3_last_login_at: "Dernière connexion v3",
  deleted_at: 'Date de suppression',
  api_access: "Accès API",
  deleted_user: "Utilisateur supprimé",
  delete_users: "Supprimer les utilisateurs",
  deletion_successful: "Suppression effectuée avec succès",
  deletion_partial: "Suppression partiellement effectuée avec succès",
  // User creation
  user_creation: "Créer un utilisateur",
  user_API: "Créer un utilisateur API ?",
  is_user_admin: "Est-ce que le futur utilisateur sera un administrateur ?",
  user_successfully_created: "Utilisateur créé avec succès",
  // User_update
  user_update: "Mettre à jour un utilisateur",
  v1_admin_rights: "Donner a cet utilisateur les droits d'administration ?",
  user_successfully_updated: "Utilisateur mis à jour avec succès",
  // Paragon
  selection: "Sélection",
  select_page: "Sélectionner toute la page",
  select_all_filtered: "Sélectionner tous les résultats filtrés",
  select_file: "Seleccione un archivo",
  list: "Liste",
  asc: "Ascendant",
  desc: "Descendant",
  action: "Actions",
  filter: "Filtres",
  clear_filters: "Vider les filtres",
  search: "Rechercher",
  no_data_available: "Aucune donnée disponible",
  // Date
  from: "À partir du",
  to: "Jusqu'au",
  select_only_one_date: "Vous n'avez sélectionné qu'une date.",
  filter_without_date: "Filtrer les résultats sans dates ?",
  do_you_want: "Voulez-vous",
  // Worker List
  filter_by_first_last_name: "Filtrer par noms ou prénoms",
  separate_search_with_commas: "Séparez vos éléments de recherche (noms et prénoms) par des virgules pour une recherche multiple"
}
