// state.ts
import Vue from "vue"

export const state = Vue.observable({
  locale: localStorage.getItem('locale') ?? 'fr',
  theme: (localStorage.getItem('theme') === 'true'),
  token: localStorage.token ?? false,
  agency: localStorage.agency ?? null,
  spaceName: localStorage.agency_name ?? null,
  user: localStorage.user?? '[]',
  view: JSON.parse(localStorage.getItem('view') ?? '{}')
})

export const mutations = {
  setLocale(newVal: string): void {
    state.locale = newVal
    localStorage.setItem("locale", newVal)
  },
  setTheme(newVal: boolean): void {
    state.theme = newVal
    localStorage.setItem("theme", String(newVal))
  },
  login(token: string): void {
    state.token = token
    localStorage.setItem("token", token)
  },
  logout(): void {
    localStorage.removeItem("token")
    state.token = false
  },
  setAgency(agency: string): void {
    state.agency = agency
    localStorage.setItem("agency", agency)
  },
  setSpaceName(spaceName: string): void {
    state.spaceName = spaceName
    localStorage.setItem("agency_name", spaceName)
  },
  setUser(user: string): void {
    state.user = user
    localStorage.setItem("user", user)
  },
  setView(name: string, title: string, view: string): void {
    state.view[name] = {[title]: view}
    localStorage.setItem("view", JSON.stringify(state.view))
  },
  resetSpaces(): void {
    state.spaceName = null
    state.agency = null
    localStorage.removeItem("agency")
    localStorage.removeItem("agency_name")
  }
}
