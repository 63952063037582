
import Vue from "vue"
import Menu from "@/components/Menu.vue"
import ModalSpace from "@/components/ModalSpace.vue"
import Snackbar, { SnackBarData } from "@/components/Snackbar.vue"
import { EventBus } from "@/plugins/eventBus"

export default Vue.extend({
  components: {
    Menu,
    ModalSpace,
    Snackbar
  },
  data: () => ({
    desktopMenuExpanded: false,
    mobileMenuExpanded: false,
    snackbar: {}
  }),
  computed: {
    menuTitle: function() {
      if (this.desktopMenuExpanded) {
        return this.$vuetify.lang.t('$vuetify.collapse_menu')
      } else {
        return this.$vuetify.lang.t('$vuetify.expand_menu')
      }
    },
    isRouteRelaxed: function() {
      return this.$route.meta?.relaxed === false
    }
  },
  mounted() {
    EventBus.$on('snackbar', (snackbar: SnackBarData) => {
      this.snackbar = snackbar
    })
  }
})
